import React, { memo } from 'react';

import Page from '@components/Page';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

const MostWanted = dynamic(() => import('./MostWanted'));
const Banner = dynamic(() => import('./Banner'));
const Products = dynamic(() => import('./Products'));
const MainCategories = dynamic(() => import('./MainCategories'));
const Services = dynamic(() => import('./Services'));

const Home = ({ SEO, banners, categories }) => (
  <Page seo={SEO}>
    {banners && <Banner banners={banners} />}
    <MainCategories />
    {categories && <Products categories={categories} />}
    <MostWanted />
    <Services />
  </Page>
);

Home.propTypes = {
  SEO: PropTypes.object.isRequired,
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      mobile_image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      url_link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      pages: PropTypes.arrayOf(PropTypes.any).isRequired,
    }),
  ).isRequired,
};

export default memo(Home);
