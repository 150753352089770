import React, { memo, useCallback } from 'react';

import { Row, Col, CardProduct, Text } from '@printi/printi-components';
import { formatMoney, cloudFront } from '@utils';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import * as S from './Home.styles';

const Products = ({ categories }) => {
  const router = useRouter();

  const { products } = categories[0].pages[0];
  const { dataLayer } = categories[0].pages[0];

  const handleChooseProduct = useCallback(
    (e, slug, index) => {
      e.preventDefault();
      global.dataLayer = global.dataLayer || [];
      global.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: ('list', 'home'),
            products: [dataLayer.ecommerce.impressions[index]],
          },
        },
      });

      router.push(slug, undefined, { shallow: true });
    },
    [router, dataLayer],
  );

  return (
    <S.Products>
      <Row role="row">
        <Col xs={12}>
          <Text as="h2" size="xxlg" weight="bold" margin="0 0 1.5rem">
            Os mais vendidos
          </Text>
        </Col>
      </Row>
      <Row role="row">
        <Col xs={12}>
          {products &&
            products.map(({ id, slug, image_small, title, minimum_price, minimum_quantity }, index) => (
              <S.Product key={id.toString()}>
                <CardProduct
                  href={`/${slug}`}
                  onClick={(e) => handleChooseProduct(e, `/${slug}`, index)}
                  title={title}
                  label="a partir de"
                  price={formatMoney(minimum_price || 0)}
                  unity={minimum_quantity === null ? '0' : minimum_quantity.toString()}
                >
                  <img
                    src={`${cloudFront}${image_small?.file}`}
                    alt={image_small?.alt}
                    title={title}
                    width={350}
                    height={350}
                    loading="lazy"
                  />
                </CardProduct>
              </S.Product>
            ))}
        </Col>
      </Row>
    </S.Products>
  );
};

Products.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      pages: PropTypes.arrayOf(PropTypes.any).isRequired,
    }),
  ).isRequired,
};

export default memo(Products);
