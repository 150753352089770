import { useCallback, useState } from 'react';

import { customApi } from '@utils';

const useFetchMostWanted = () => {
  const [mostWanted, setMostWanted] = useState([]);
  const [isMostWantedLoading, setIsMostWantedLoading] = useState(false);

  const getMostWanted = useCallback(async () => {
    setIsMostWantedLoading(true);

    const { data } = await customApi.get('/v1/home-most-wanted');
    data.sort((a, b) => (a.position > b.position ? 1 : -1));
    setMostWanted(data);

    setIsMostWantedLoading(false);
  }, []);

  return { getMostWanted, mostWanted, isMostWantedLoading };
};

export default useFetchMostWanted;
