import { useCallback, useState } from 'react';

import { customApi } from '@utils';

const useFetchMainCategories = () => {
  const [mainCategories, setMainCategories] = useState([]);
  const [isMainCategoriesLoading, setIsMainCategoriesLoading] = useState(false);

  const getMainCategories = useCallback(async () => {
    setIsMainCategoriesLoading(true);
    await customApi.get('/v1/main-categories').then(({ data }) => setMainCategories(data));

    setIsMainCategoriesLoading(false);
  }, []);

  return { getMainCategories, mainCategories, isMainCategoriesLoading };
};

export default useFetchMainCategories;
