import React, { useEffect, useRef, memo, useState } from 'react';

import { cloudFront, useIntersectionObserver } from '@utils';
import PropTypes from 'prop-types';

import * as S from './Home.styles';

const CarouselArrow = ({ onClick, rotate }) => (
  <S.CarouselArrow rotate={rotate ? 1 : 0} onClick={onClick} aria-label="Mover carrossel">
    <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24.06" height="75.64" viewBox="0 0 12.03 37.82">
      <path d="M.62 37.8a.564.564 0 0 1-.3-.08.583.583 0 0 1-.22-.81l10.53-18.23L.1.9A.583.583 0 0 1 .32.09a.6.6 0 0 1 .82.21l10.89 18.38L1.15 37.5a.63.63 0 0 1-.53.3z" />
    </svg>
  </S.CarouselArrow>
);

CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  rotate: PropTypes.bool,
};

const sendDataToDataLayer = ({ title, index, position, eventName }) => {
  // Check if dataLayer exists
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null }); // Clears the previous ecommerce
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        items: [
          {
            creative_name: title,
            creative_slot: `banner_principal_${position}`, // Parameter suggestion based on banner
            promotion_id: `bp${1 + index}`, // (b = banner, p = position) followed by the position number of the banner in the slide
            promotion_name: title, // @todo - later on maybe here maybe we can add images 'alt' attribute
          },
        ],
      },
    });
  }
};

const BannerItem = ({ slug, title, image, mobile_image, url_link, target_link, position, index }) => {
  const bannerRef = useRef(null);
  const [dataLayerSent, setDataLayerSent] = useState(false);
  const isVisible = useIntersectionObserver(bannerRef, {
    threshold: 0.1, // Trigger when at least 10% of the banner is visible
  });

  useEffect(() => {
    if (isVisible && !dataLayerSent) {
      sendDataToDataLayer({ title, index, position, eventName: 'view_promotion' });
      setDataLayerSent(true);
    }
  }, [isVisible, title, index, position, dataLayerSent]);

  return (
    <a
      ref={bannerRef}
      key={slug}
      onClick={() => sendDataToDataLayer({ title, index, position, eventName: 'click_promotion' })}
      href={url_link}
      target={target_link}
      rel={target_link === '_blank' ? 'noopener noreferrer' : ''}
    >
      <S.Picture>
        <source media="(min-width: 769px)" srcSet={`${cloudFront}${encodeURI(image.filename)}`} width="1122" />
        <source media="(max-width: 768px)" srcSet={`${cloudFront}${encodeURI(mobile_image.filename)}`} width="768" />
        <img
          src={`${cloudFront}${encodeURI(mobile_image.filename)}`}
          width="768"
          alt={title}
          loading={index > 0 ? 'lazy' : 'eager'}
        />
      </S.Picture>
    </a>
  );
};

BannerItem.propTypes = {
  slug: PropTypes.string.isRequired,
  target_link: PropTypes.string,
  title: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  mobile_image: PropTypes.shape({
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }).isRequired,
  url_link: PropTypes.string.isRequired,
  index: PropTypes.number,
};

const Banner = ({ banners }) => (
  <S.Carousel
    aria-roledescription="carousel"
    aria-live="polite"
    autoplay
    responsive={[
      {
        breakpoint: 6000,
        settings: {
          dots: true,
          prevArrow: <CarouselArrow />,
          nextArrow: <CarouselArrow rotate />,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          dots: false,
          arrows: false,
          prevArrow: null,
          nextArrow: null,
        },
      },
    ]}
  >
    {banners.map(({ slug, title, image, mobile_image, url_link, target_link, position }, index) => (
      <BannerItem
        slug={slug}
        title={title}
        image={image}
        mobile_image={mobile_image}
        url_link={url_link}
        target_link={target_link}
        position={position}
        index={index}
      />
    ))}
  </S.Carousel>
);

Banner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      mobile_image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      url_link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(Banner);
