import React, { memo } from 'react';

import PropTypes from 'prop-types';

import * as S from './Home.styles';

const CarouselArrow = ({ onClick, rotate, changeDirection, isActive }) => {
  const handleClick = () => {
    onClick?.();
    changeDirection();
  };

  return (
    <S.SliderArrow
      type="button"
      rotate={rotate ? 1 : 0}
      onClick={handleClick}
      aria-label="Mover carrossel"
      isActive={isActive}
    >
      <svg width="20" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.50833 2.5L17.5 17.545L2.5 32.5"
          stroke="#009BFF"
          strokeWidth="3.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </S.SliderArrow>
  );
};

CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  rotate: PropTypes.bool,
  changeDirection: PropTypes.func,
  isActive: PropTypes.bool,
};

export default memo(CarouselArrow);
