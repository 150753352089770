import { theme, Image } from '@printi/printi-components';
import styled from 'styled-components';

const { breakpoint, color, borders } = theme;

export const ContainerGrid = styled.section`
  width: 100%;
  margin-bottom: 4rem;

  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    'product1 product1 product2 product2'
    'product1 product1 product3 product4';
  grid-template-rows: repeat(2, 15em);
  grid-template-columns: repeat(2, 1fr);

  > a:nth-child(1) {
    grid-area: product1;
  }

  > a:nth-child(2) {
    grid-area: product2;
  }

  > a:nth-child(3) {
    grid-area: product3;
  }

  > a:nth-child(4) {
    grid-area: product4;
  }

  @media screen and (max-width: ${breakpoint.hd}) {
    height: auto;
    grid-template-areas: 'product1 product2 product3 product4';
    grid-template-rows: 12.5rem;
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: ${breakpoint.lg}) {
    height: auto;
    grid-template-areas:
      'product1 product2'
      'product3 product4';
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${breakpoint.md}) {
    grid-template-areas:
      'product1 product1'
      'product2 product2'
      'product3 product4';
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MostWantedImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0px 0px;

  @media screen and (max-width: ${breakpoint.hd}) {
    height: 12.5rem;
    object-fit: cover;
    object-position: 0px 0px;
  }

  @media screen and (max-width: ${breakpoint.lg}) {
    height: 18.75rem;
    object-fit: cover;
    object-position: 0px 0px;
  }

  @media screen and (max-width: ${breakpoint.md}) {
    height: 12.5rem;
    object-fit: cover;
    object-position: 0px 0px;
  }
`;

export const LinkImage = styled.a`
  &:focus {
    border: ${borders.sizes.md} ${color.grayscale.black} solid;
  }
`;
