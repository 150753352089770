import React, { memo, useEffect } from 'react';

import { Row, Col, Text, Loader } from '@printi/printi-components';
import Link from 'next/link';

import * as S from './MostWanted.styles';
import useFetchMostWanted from './useFetchMostWanted';

const MostWanted = () => {
  const cloudFront = process.env.CLOUDFRONT;
  const { getMostWanted, mostWanted, isMostWantedLoading } = useFetchMostWanted();

  useEffect(() => {
    getMostWanted();
  }, [getMostWanted]);

  return (
    <Row>
      <Col xs={12}>
        {isMostWantedLoading ? (
          <Loader size="medium" wrapper="20rem" />
        ) : (
          <>
            {mostWanted.length > 0 && (
              <>
                <Text as="h2" size="xxlg" weight="bold" margin="0 0 1.5rem">
                  Os mais procurados
                </Text>
                <S.ContainerGrid>
                  {mostWanted.map((product) => (
                    <Link key={product.title} href={product.link} as={product.link} passHref>
                      <S.LinkImage target={product.target} tabIndex="0">
                        <S.MostWantedImage
                          src={`${cloudFront}${product.image.path}`}
                          srcSet={`${cloudFront}${product.image.path}`}
                          alt={product.title}
                        />
                      </S.LinkImage>
                    </Link>
                  ))}
                </S.ContainerGrid>
              </>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default memo(MostWanted);
