import React, { memo, useCallback, useEffect, useState } from 'react';

import { Row, Col, Text, Loader } from '@printi/printi-components';
import { useWindowSize } from '@utils';
import Link from 'next/link';

import CarouselArrow from './CarouselArrow';
import * as S from './Home.styles';
import useFetchMainCategories from './useFetchMainCategories';
const cloudFront = 'https://d2ofpir5gh0cbr.cloudfront.net';

const MainCategories = () => {
  const [showSlider, setShowSlider] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const { getMainCategories, mainCategories, isMainCategoriesLoading } = useFetchMainCategories();
  const windowSize = useWindowSize();

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    prevArrow: <CarouselArrow changeDirection={() => setHasScrolled(false)} isActive={hasScrolled} />,
    nextArrow: <CarouselArrow rotate changeDirection={() => setHasScrolled(true)} isActive={!hasScrolled} />,
  };

  const renderMainCategories = useCallback(
    () =>
      mainCategories.map(({ position, image, title, menu_link }) => (
        <Link key={position} href={menu_link} as={menu_link} passHref>
          <S.Category tabIndex="0">
            <S.CategoryBg>
              <S.CategoryPic>
                <source media="(max-width: 108px)" srcSet={`${cloudFront}${image.path}`} type="image/webp" />
                <source media="(max-width: 108px)" srcSet={`${cloudFront}${image.path}`} type="image/png" />
                <img width={132} height={138} loading="lazy" src={`${cloudFront}${image.path}`} alt={image.title} />
              </S.CategoryPic>
            </S.CategoryBg>
            <Text size="lg" weight="bold" align="center">
              {title}
            </Text>
          </S.Category>
        </Link>
      )),
    [mainCategories],
  );

  useEffect(() => {
    getMainCategories();
  }, [getMainCategories]);

  useEffect(() => {
    setShowSlider(windowSize?.width > 990 && windowSize?.width <= 1175);
  }, [windowSize]);

  return (
    <>
      {isMainCategoriesLoading && <Loader size="medium" wrapper="20rem" />}
      {!isMainCategoriesLoading && (
        <S.SectionCategories>
          <Row role="row">
            <Col xs={12}>
              <S.TitleCategories>Categorias de produto</S.TitleCategories>
              {showSlider ? (
                <S.SliderMainCategories arrows {...sliderSettings}>
                  {renderMainCategories()}
                </S.SliderMainCategories>
              ) : (
                <S.MainCategories>{renderMainCategories()}</S.MainCategories>
              )}
            </Col>
          </Row>
        </S.SectionCategories>
      )}
    </>
  );
};

export default memo(MainCategories);
