import Slider from 'react-slick';

import { theme, Col } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, breakpoint, color, space } = theme;

export const Carousel = styled(Slider)`
  position: relative;
  margin-top: -${space.xl};
  margin-bottom: ${space.xl3};

  .slick-dots {
    position: absolute;
    bottom: 1rem;
    right: 50%;
    transform: translateX(50%);
    width: auto;
    height: auto;
    line-height: 0;

    li {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.5rem;

      button {
        background-color: hsla(0, 0%, 100%, 0.3);
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        padding: 0;
        transition: 0.3s ease;

        &:before {
          display: none;
        }

        &:hover,
        &:focus {
          background-color: #fff;
        }
      }

      &.slick-active {
        button {
          background-color: #fff;
        }
      }
    }
  }
`;

export const CarouselArrow = styled.button`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 3.5rem;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${color.grayscale.gray};
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    color: ${color.primary.pure};
  }

  svg {
    width: 1.25rem;
  }

  ${(p) =>
    p.rotate
      ? `
      right: 0;
    `
      : `
      left: 0;

      > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Picture = styled.picture`
  display: block;
  width: 100%;
  min-height: 10rem;
  cursor: pointer;

  img,
  source {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const Products = styled.section`
  margin-bottom: ${space.xl};
`;

export const Product = styled.div`
  display: inline-flex;
  vertical-align: top;
  width: 50%;
  margin-bottom: ${space.lg};
  padding: 0 ${space.xs2};

  @media (min-width: ${breakpoint.md}) {
    width: 33.33%;
  }

  @media (min-width: ${breakpoint.lg}) {
    width: 25%;
  }

  @media (min-width: ${breakpoint.xl}) {
    width: 20%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &:focus {
    border: ${borders.sizes.md} ${color.grayscale.black} solid;
  }
`;

export const Section = styled.section`
  margin-bottom: ${space.xl4};
`;

export const TitleCategories = styled.h2`
  font-size: 0;
  color: #000;
`;

export const SectionCategories = styled(Section)`
  @media (max-width: 990px) {
    display: none;
  }

  width: 100%;
  overflow-x: auto;
`;

export const MainCategories = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  overflow-x: visible;
`;

export const Category = styled.a`
  text-align: center;
  color: ${color.grayscale.gray};
  margin-right: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    max-width: 128px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    text-decoration: none;
  }

  @media (min-width: ${breakpoint.lg}) {
    min-width: 8rem;
  }

  &:focus {
    border: ${borders.sizes.md} ${color.grayscale.black} solid;
  }
`;

export const CategoryBg = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
  border-radius: ${borders.radius.circle};
  background-color: ${color.primary.pure};
`;

export const CategoryPic = styled.picture`
  display: flex;
  cursor: pointer;

  img,
  source {
    max-width: 108px;
    width: auto;
    height: auto;
  }
`;

export const Classes = styled.div`
  display: flex;
`;

export const ClassItem = styled.a`
  position: relative;
  display: block;
  width: 100%;
  height: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const LeftItems = styled.div`
  width: 100%;
  margin-bottom: 4.5%;

  @media (min-width: ${breakpoint.md}) {
    display: inline-flex;
    vertical-align: top;
    width: 59%;
    margin-right: 2%;
    margin-bottom: 0;
  }
`;

export const RightItems = styled.div`
  width: 100%;

  @media (min-width: ${breakpoint.md}) {
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 39%;
  }
`;

export const RightItemsBottom = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4.5%;

  a:first-of-type {
    margin-right: 4%;
  }
`;

export const Services = styled.a`
  display: block;
  margin-bottom: ${space.lg};
  background-color: ${color.grayscale.white};
  border-radius: ${borders.radius.md};
  transition: box-shadow 0.16s ease-in-out;

  &:hover {
    text-decoration: none;
    box-shadow: 0 1px 5px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: ${space.sm};
  }

  &:focus {
    border: ${borders.sizes.md} ${color.grayscale.black} solid;
  }
`;

export const ColServices = styled(Col)`
  display: flex;
`;

export const SliderMainCategories = styled(Slider)`
  display: flex;
  justify-content: center;

  .slick-dots {
    button {
      background-color: transparent;
      color: transparent !important;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;
      transition: 0.3s ease;

      &:before {
        display: none;
      }
    }
  }

  .slick-list {
    width: 90%;
    margin-left: 16px;
  }
`;

export const SliderArrow = styled.button`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 3.5rem;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: transparent;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  ${(p) =>
    p.rotate
      ? `
      right: 0;
    `
      : `
      left: 0;

      > svg {
        transform: rotate(180deg);
      }
    `}

  ${(p) =>
    p.isActive
      ? `
      path {
        stroke: ${color.primary.pure};
      }`
      : `
      path {
        stroke: #8c9093
      }
    `}

  svg {
    width: 1.25rem;
  }
`;
