import React, { memo, useMemo } from 'react';

import { Row, Col, Text } from '@printi/printi-components';
import Link from 'next/link';

import * as S from './Home.styles';

const Services = () => {
  const cloudFront = process.env.CLOUDFRONT;
  const services = useMemo(
    () => [
      {
        id: 1,
        title: 'Indique a Printi',
        link: '/indique-a-printi',
        image: {
          path: '/next/home/indique-a-printi.webp',
          fallback: '/next/home/indique-a-printi.png',
        },
        description:
          'Por cada indicação bem-sucedida, você ganha R$ 20 em crédito, e quem é indicado recebe um desconto exclusivo ao usar seu cupom. Acesse e conheça as regras!',
      },
      {
        id: 2,
        title: 'Vendas Corporativas',
        link: '/venda-corporativa',
        image: {
          path: '/next/home/servico-vendas-corporativas.webp',
          fallback: '/next/home/servico-vendas-corporativas.jpg',
        },
        description:
          'No atendimento corporativo, você usufrui de condições especiais de compra, pagamento faturado e consultoria dedicada para ajudá-lo a encontrar a melhor solução para o seu negócio.',
      },
      {
        id: 3,
        title: '4Business',
        link: '/4business',
        image: {
          path: '/next/home/servico-4-business-printi.webp',
          fallback: '/next/home/servico-4-business-printi.jpg',
        },
        description:
          'Crie uma experiência de compra padronizada e exclusiva para franquias e/ou colaboradores, oferecendo de brindes corporativos a materiais promocionais através de uma plataforma de e-commerce dedicada, gerando autonomia para os solicitantes e desafogando os setores de marketing e compras.',
      },
      {
        id: 4,
        title: 'Televendas',
        link: '/televendas-printi',
        image: {
          path: '/next/home/televendas.webp',
          fallback: '/next/home/televendas.jpg',
        },
        description:
          'O canal de Televendas da Printi está pronto para receber sua ligação e te ajudar a personalizar de forma descomplicada! Peça orçamentos, adquira produtos ou solicite montagem de arte. Nosso horário de atendimento é de segunda a sexta-feira das 9h às 18h por telefone no número (11) 4118-3820.',
      },
    ],
    [],
  );

  return (
    <S.Section>
      <Row role="row">
        <Col xs={12}>
          <Text as="h2" size="xxlg" weight="bold" margin="0 0 1.5rem">
            Soluções
          </Text>
        </Col>
      </Row>

      <Row role="row">
        {services.map(({ id, title, link, description, image }) => (
          <S.ColServices key={id} xs={6} md={4} lg={3}>
            <Link href={link} as={link} passHref>
              <S.Services>
                <picture>
                  <source srcSet={cloudFront + image.path} type="image/webp" />
                  <source srcSet={cloudFront + image.fallback} type="image/jpeg" />
                  <img src={cloudFront + image.fallback} alt={title} width={250} height={214} loading="lazy" />
                </picture>
                <Text size="lg" weight="bold" align="center" padding="0 1rem 0.25rem">
                  {title}
                </Text>
                <Text size="sm" weight="lighter" align="center" padding="0.25rem 1rem 1rem">
                  {description}
                </Text>
              </S.Services>
            </Link>
          </S.ColServices>
        ))}
      </Row>
    </S.Section>
  );
};

export default memo(Services);
