/* eslint-disable no-console */
import React from 'react';

import { customStaticApi } from '@utils';
import axios from 'axios';

import Home from '../contents/Home';

const HomeRoute = (props) => <Home {...props} />;

export async function getStaticProps() {
  const response = await customStaticApi(axios)
    .get('/v1/landing_page_get/homepage')
    .then(({ data: { seo, contentBlocks } }) => {
      const { full_rotative_banners, product_categories_carousel } = contentBlocks;
      const banners = full_rotative_banners.contentList.sort((a, b) => (a.position > b.position ? 1 : -1));
      return {
        SEO: seo,
        banners,
        categories: product_categories_carousel.contentList,
      };
    })
    .catch((error) => console.error('Error in build the home page: ', error));

  const revalidate = Number(process.env.REVALIDATE_TIME) || 43200;

  return {
    props: { ...response },
    revalidate,
  };
}

export default HomeRoute;
